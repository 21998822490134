import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/Android/InstarVision/Wizard/Add_Camera/New_P2P_Cam/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "android-app-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#android-app-installation-wizard",
        "aria-label": "android app installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Android App Installation Wizard`}</h2>
    <h3 {...{
      "id": "add-a-new-p2p-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-new-p2p-camera",
        "aria-label": "add a new p2p camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a New P2P Camera`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/81315/Android_Wizard_New_P2P_Cam_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/ElEQVQ4y6WSSXObQBBGuSs66OiK0EYiCyG6Z4FhGJgBIRTJlrXZ8lL5/z8kBVrssstJKnnFYepr3nRBt2Xbtuu6rVarcabf73sVk3a3P6xpNBrNZvNLTafTGY1GgNhzvluu6zqOM51OKaW9Xm8wGHS7Xbv9td37dkUX19cjx3HSNDU1w+Gwqtp22+5c8TtrPB57ngcAvu97ZyY13uSUwJkqvFR9sGgNqaHvIW+rH14g1jHFM5fzRfgsJ+QkU8oYY5xxzmredbuEb8+1jEjAT1WcGa3TRGud57nWmhKkBAExjpUxmTamKIrZbDYtiizLlFKVTAPBzC0zS6YX3NwIEYVhWGmBQi4JAkGgBBkBo9MszznnVX+CFMHiYZQUt6q4UXlppqUxRkqJCMgjQhmy0I9LUCWoOQhNCWGMEayuhlBbPEpkuRHFWuoijoRKEqXUsbNPRSTE7vC8fXjaHl6294fVarVeryMhxrL00jsrEmK5mJtUyUjIOFZKSSkpQWQRUB5wVuRmatJZbhKlGK+omld/klmICACIr9M6zwMJAWChJzIvzNww92mAAJVFCLIAWWiRz0FEEcnd48vD88/N/nG3v9/ttpvNRuvUjZeu2f9BDoKgnBWnp5zN5z+EiBilwCWEye/kI8e1v3D8KIr1qMjf8brQH9bzH/kv+RebHNJgnJ62MAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/70cfe96f4a037b17278c6fab1dfc0f12/e4706/Android_Wizard_New_P2P_Cam_d01.avif 230w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/d1af7/Android_Wizard_New_P2P_Cam_d01.avif 460w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/7f308/Android_Wizard_New_P2P_Cam_d01.avif 920w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/e1c99/Android_Wizard_New_P2P_Cam_d01.avif 1380w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/6e1e4/Android_Wizard_New_P2P_Cam_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/70cfe96f4a037b17278c6fab1dfc0f12/a0b58/Android_Wizard_New_P2P_Cam_d01.webp 230w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/bc10c/Android_Wizard_New_P2P_Cam_d01.webp 460w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/966d8/Android_Wizard_New_P2P_Cam_d01.webp 920w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/445df/Android_Wizard_New_P2P_Cam_d01.webp 1380w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/87a8c/Android_Wizard_New_P2P_Cam_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/70cfe96f4a037b17278c6fab1dfc0f12/81c8e/Android_Wizard_New_P2P_Cam_d01.png 230w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/08a84/Android_Wizard_New_P2P_Cam_d01.png 460w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/c0255/Android_Wizard_New_P2P_Cam_d01.png 920w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/b1001/Android_Wizard_New_P2P_Cam_d01.png 1380w", "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/81315/Android_Wizard_New_P2P_Cam_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/70cfe96f4a037b17278c6fab1dfc0f12/c0255/Android_Wizard_New_P2P_Cam_d01.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Start by entering the `}<strong parentName="li">{`Cameras`}</strong>{` menu and select the `}<strong parentName="li">{`Add Camera`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` You can now continue with adding the the remote access via the `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/Android/P2P/"
        }}>{`Point2Point (P2P)`}</a>{` or `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/Android/DDNS/"
        }}>{`DDNS`}</a>{` service.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/13cd6222c0dc405b8c1c08184cb7915f/81315/Android_Wizard_New_P2P_Cam_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACm0lEQVQ4y43S3UtacRzHcf+AHq438JzgBBE+lOnUkx6TIIqiwWg+zKzl03w4k+bMlQaaVtoyNT15fr/feeocL9bNuo7BYGP9K9v+hnY3ZjQIZu51/+YDX74qgiBwHDebzVarFcOwR3ced42Pj4+Ojg4NDQ0PDw8ODg4MDGAYNjY2ptFo1Gq1amlpcfnpstfr8a36XC6X2+3ydPl8Pu8Lr06nIwjC6XSSJInjOEEQIyMjGIap1Wocx1X1eu3sjGFZFkIoCIIkSbIsK4pycfGh01Hm5uZMJhNJkjabTa/Xa7t0d1StVqvdbrMsC9g/AAAIIY7jJEkEACwsLFAUNTs7a7PZjEaj4T4VwzB/m1scx/E8x/N8u92en5+fnp6mKGqia/I+1clJ9bR5KghCp0tRFFmWOx1FkiQA2JkZh0ajmexBVakcNhp1hmEEQRBFURAEnudFUTw/lwAATqdTq9UaDIZ/x8XiXrX6vlarQgg5jkMIAQggBJIkQQj7xPl8vnpyXKvVWJblOA52IcTJsoxQvxgAcHn5URAEAMDtMkKI53lZVhBCfeKrT1c3v26ur781Go1m85RhGAhhN/6PZYjgj5/fv3z9vL9fOjoqHx8fNRp1lm2Lotj/YJnMVqlULBTy2Wx2dzdXKORLpVKlUm42m61Wq09M03QqlUqn08lkcnNzM5PZ2snuFIt75fLhwcG+w+HQ6XQ942j0VTr9NpfLJhLxSCQSCYdpOpHLZXO57Pb2O4qiHopDoUA8Hkul3oTDoVAoGAwFAsGNZDIZj8cSdMJmtz8UB4Ib0ViUfk3H47FgMOBf9/v9qx6Pe2Xl2Yr7udlimdBP9HxP/5rv5cb62vra4tKi3T5NkhaL5YnZbLJaTHbrlHHKMGno6Tcvx3FhyrpQ4wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13cd6222c0dc405b8c1c08184cb7915f/e4706/Android_Wizard_New_P2P_Cam_d02.avif 230w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/d1af7/Android_Wizard_New_P2P_Cam_d02.avif 460w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/7f308/Android_Wizard_New_P2P_Cam_d02.avif 920w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/e1c99/Android_Wizard_New_P2P_Cam_d02.avif 1380w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/6e1e4/Android_Wizard_New_P2P_Cam_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/13cd6222c0dc405b8c1c08184cb7915f/a0b58/Android_Wizard_New_P2P_Cam_d02.webp 230w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/bc10c/Android_Wizard_New_P2P_Cam_d02.webp 460w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/966d8/Android_Wizard_New_P2P_Cam_d02.webp 920w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/445df/Android_Wizard_New_P2P_Cam_d02.webp 1380w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/87a8c/Android_Wizard_New_P2P_Cam_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13cd6222c0dc405b8c1c08184cb7915f/81c8e/Android_Wizard_New_P2P_Cam_d02.png 230w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/08a84/Android_Wizard_New_P2P_Cam_d02.png 460w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/c0255/Android_Wizard_New_P2P_Cam_d02.png 920w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/b1001/Android_Wizard_New_P2P_Cam_d02.png 1380w", "/en/static/13cd6222c0dc405b8c1c08184cb7915f/81315/Android_Wizard_New_P2P_Cam_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/13cd6222c0dc405b8c1c08184cb7915f/c0255/Android_Wizard_New_P2P_Cam_d02.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Use the app to scan the QR code from your camera or camera package. If your camera does not have a QR code, please manually select the camera model in the next step.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Select if your camera is a Pan&Tilt model or a camera with a fixed camera head.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8e4da9977315d6cb679658225c860f94/81315/Android_Wizard_New_P2P_Cam_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyElEQVQ4y53SzW/cRBQAcF/oJcmlUtTmY73ebfBmd2zPl2fG9ng944/NBxJSmnTdTbRalhaUXCokKrhwRgL+A24cuHJG6g0OcIT/of9D1VurmU3bFDjx0ztYY7/3Zt7YAQD0er0Na83yPA8AMAJga9f1rXVrbW1tfX3ddd3hcBhGUafbd3zf39vb01ozxrrdrud5ruvu7mx3vHvb5Hgw2O/3+1qXhJBOp9Pr9VzX7XR2dzvuVvyxMxqNAACcc2phixISBMEIhCMrDMM4jq9fUWr2ZdICh3POGFNKVVXVNI3WujQqsxxTxpgQgnOu3ijLMkkSxhhnzKnrerlcLhaLi4uLmXV6eopgFOEYJdVYZnVdryqenZ1Np9PJZFKWZV3XVVU5UsqmaQ4ODvI8N/U4z7JMcM644FkhBJdSCiEghKujQQhDKwgC07lt2+l02rbtbDZr29ZWiblIWDqWWVZVtRACIQQtZFFKpZSO1no2m52fn5+cnBxbjDEII0himJSqGB8dHXPO4xsopZzzPM+d8XislNJaF5ZSSkqZWTJLRWLkeY7/BULoHB4eXl5eXl1dLZfL+Xy+WCzSNI3CABKGsslYZmVZpWmK/ouTJImUslAqTVNuMNMrTc1diCRNkrquC6UwIW+DULq6NgchRAgplKrq5uDwSJfmtieThlKCEMzMwCo9zhgC7wIH9udgJhliHPIiijNAJaAyjGXACogpQtC0IRioBx9OPh80nw4mj/zm8aCaQzt9x24ebi1/vv30b++rP/pf/3n76V93PvsFYdMZIoyC4eblrx989+rO9y/u/vDy1revNr55DqlAMLpO3pn/uPnkd++LZ/6Xzzaf/Lb9yU8IY4QgQhhBCORHg3I21NOhbvf1w2Fx36wjtEpGONwngY+AD4FPAh+H+zeniiOAw+GNGF1P++0HJrCN1fM/4ffjveT/4zXbd+1FOvM7IAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e4da9977315d6cb679658225c860f94/e4706/Android_Wizard_New_P2P_Cam_d03.avif 230w", "/en/static/8e4da9977315d6cb679658225c860f94/d1af7/Android_Wizard_New_P2P_Cam_d03.avif 460w", "/en/static/8e4da9977315d6cb679658225c860f94/7f308/Android_Wizard_New_P2P_Cam_d03.avif 920w", "/en/static/8e4da9977315d6cb679658225c860f94/e1c99/Android_Wizard_New_P2P_Cam_d03.avif 1380w", "/en/static/8e4da9977315d6cb679658225c860f94/6e1e4/Android_Wizard_New_P2P_Cam_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8e4da9977315d6cb679658225c860f94/a0b58/Android_Wizard_New_P2P_Cam_d03.webp 230w", "/en/static/8e4da9977315d6cb679658225c860f94/bc10c/Android_Wizard_New_P2P_Cam_d03.webp 460w", "/en/static/8e4da9977315d6cb679658225c860f94/966d8/Android_Wizard_New_P2P_Cam_d03.webp 920w", "/en/static/8e4da9977315d6cb679658225c860f94/445df/Android_Wizard_New_P2P_Cam_d03.webp 1380w", "/en/static/8e4da9977315d6cb679658225c860f94/87a8c/Android_Wizard_New_P2P_Cam_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e4da9977315d6cb679658225c860f94/81c8e/Android_Wizard_New_P2P_Cam_d03.png 230w", "/en/static/8e4da9977315d6cb679658225c860f94/08a84/Android_Wizard_New_P2P_Cam_d03.png 460w", "/en/static/8e4da9977315d6cb679658225c860f94/c0255/Android_Wizard_New_P2P_Cam_d03.png 920w", "/en/static/8e4da9977315d6cb679658225c860f94/b1001/Android_Wizard_New_P2P_Cam_d03.png 1380w", "/en/static/8e4da9977315d6cb679658225c860f94/81315/Android_Wizard_New_P2P_Cam_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8e4da9977315d6cb679658225c860f94/c0255/Android_Wizard_New_P2P_Cam_d03.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Once your camera is connected click on `}<strong parentName="li">{`Search`}</strong>{` and the app will detect all INSTAR cameras on your local network. If your camera does not show choose to `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/P2P/"
        }}>{`add it manually`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` You will now be asked to enter the admin login of your camera. If you haven't set it up before this will be the default usermane `}<strong parentName="li">{`admin`}</strong>{` and password `}<strong parentName="li">{`instar`}</strong>{`. This can be changed later inside the app.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d4eff21408f50598f95bb4d073b4dc9/8efc2/Android_Wizard_New_P2P_Cam_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAADU0lEQVRIx+2RT2/jRBjGrWSFxDYrNW1BoRsntps4cR17xp6x4z9xkiZ2pDbbJE5UO3bUHnpBIisBX6AVYm+VQCDEHS58Ae5LOcJHWW3vIKQge0Q3YRES4tqfHo3fGc3jeeYdKpfL+b7fbDa3trYymUw2m81kMtQa2WyWpmlRFEul0mAwCIJgZ3dXFA/L5TJF0zSTQtN0Pp/f3t7O5/OFTYrFIsMw+/v7pVKJYZhCofCUqRbLHMXzfLvd5jiuVqv5vh8EwXA4rFQqB5uwLMtxHMtxLMseVCofuMtiK6Y4jpNlmWEYlmU7nY7rup7ncRxH4rAppLgfGYZ9qp0WQZcSBKFarQqCUK/XS6USTdPlcpnn+VqtxvN8NaWW8lddF5LdNaFeo9QUCKFhGMPhsNfrHR0dua7rOA5J4bpuJyWZDgaWZUFFUVRVURQKACClIISOUnq9nud5rVbL87x+v+953huz51mW1Wg0ZFkGAFC6rjuOYxiGZVmGYWCMdV1vNpuGYWiapuu6loIxJoWu65ZlmaaJMaa63e58Pp/NZqenp1EUTSaTMAyn0+l8Pg+CIEohxfn5+Xg8DoLg7OwsjuN+v09JkmSaJnGGYTgajcIwnM/n4/H45OTk8vLS9/3FYhHH8WKxOD4+nk6nFxcXpmlKkkRBCGVZTlugapoGAFAUhYRECN3HRgiRkdyL9JhyHCcMw9lsNplMptNpFEW+7+u6TlqyPv6NxIwQMk3TTjFN03Ec27YBAKIoQpg8yjpkhYRNzKqquq7b7XZN0+x0Oq1Wy7btXq83GAxUVX37TFmWMcZRFGmaRmGMSXtms1kcx6S3z54NgyBot9uapjU3IU81Go0SM8lAbgGkBpAOoSwqcgNKIlbAW4IoBaZQ5AMUVW3wzORq55Nf95Yvd5/f7j2/3Vne7i1/2l3eJvroZf7jX4rRN1ASAISbZhWhQ7Zw+UPm69U7N388ulm9+8VK+36Fvlttfbl6dJMsZr9a5T/9WZEOYdqz9ZMREg+K8bePX/z+5OpV7vruyfXde5+/3vnsde76LtHVq8cvfnv/wx8VSYCKumEmfwCqJmNbxhZRAyW6n8rYBmoTwvvUG2aoAKAA6V8lr+/fMP9XHswP5gfzP5nV/8GfqmXF3b1PZS0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d4eff21408f50598f95bb4d073b4dc9/e4706/Android_Wizard_New_P2P_Cam_d04.avif 230w", "/en/static/3d4eff21408f50598f95bb4d073b4dc9/d1af7/Android_Wizard_New_P2P_Cam_d04.avif 460w", "/en/static/3d4eff21408f50598f95bb4d073b4dc9/ec170/Android_Wizard_New_P2P_Cam_d04.avif 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d4eff21408f50598f95bb4d073b4dc9/a0b58/Android_Wizard_New_P2P_Cam_d04.webp 230w", "/en/static/3d4eff21408f50598f95bb4d073b4dc9/bc10c/Android_Wizard_New_P2P_Cam_d04.webp 460w", "/en/static/3d4eff21408f50598f95bb4d073b4dc9/712de/Android_Wizard_New_P2P_Cam_d04.webp 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d4eff21408f50598f95bb4d073b4dc9/81c8e/Android_Wizard_New_P2P_Cam_d04.png 230w", "/en/static/3d4eff21408f50598f95bb4d073b4dc9/08a84/Android_Wizard_New_P2P_Cam_d04.png 460w", "/en/static/3d4eff21408f50598f95bb4d073b4dc9/8efc2/Android_Wizard_New_P2P_Cam_d04.png 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d4eff21408f50598f95bb4d073b4dc9/8efc2/Android_Wizard_New_P2P_Cam_d04.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Please choose a name for your camera.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      